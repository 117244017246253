<template>
  <div class="admin-navbar">
    <div class="left">
      <router-link to="/reservation" class="link">
        <span class="element">{{ $t("navbar.reservation") }}</span>
      </router-link>
      <router-link to="/planning" class="link">
        <span class="element">{{ $t("navbar.planning") }}</span>
      </router-link>
    </div>
    <div class="right">
      <router-link to="/admin-fidelity" class="link">
        <span class="element">{{ $t("navbar.loyalty") }}</span>
      </router-link>
      <router-link to="/admin" class="link">
        <span class="element">{{ $t("navbar.franchise") }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminNavbar"
};
</script>

<style lang="scss" scoped>
.admin-navbar {
  width: 100%;
  background-color: #2c2c2c;
  display: flex;
  gap: 2rem;
  padding: 1.5rem 0 1rem 0;
  border-bottom: 3px solid #ffffff;
  border-radius: 0px 0px 15px 15px;
  justify-content: space-evenly;
  margin-top: -0.5rem;
  .right,
  .left {
    display: flex;
    gap: 2rem;
    .link {
      text-decoration: none;
    }
    span {
      font-family: "Inter";
      font-size: 1.2rem;
      text-transform: uppercase;
      color: white;
    }
  }
}
</style>
