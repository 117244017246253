import { defineStore } from "pinia";
import {
  getFirestore,
  doc,
  onSnapshot,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import { firebaseApp } from "../db/index";
import { reactive } from "vue";
import { formatDate } from "@/utils";
import { useAuthentificationStore } from "@/stores/authentification";

export const useNotificationsStore = defineStore("notifications", () => {
  const authentificationStore = useAuthentificationStore();
  const db = getFirestore(firebaseApp);

  const state = reactive({
    grantedUser: {},
  });

  const getGranted = () => {
    onSnapshot(doc(db, "NOTIFICATIONS/GRANTED"), (doc) => {
      const infos = { ...doc.data() };
      state.grantedUser = infos;
    });
  };

  const addGranted = async (deviceToken) => {
    const userData = authentificationStore.state?.userData;

    if (!!userData?.role) {
      const topic = userData?.role?.function.concat(userData?.role?.bar);
      await fetch(
        `${process.env.VUE_APP_BACKEND}/notifications/user-topic?users=${deviceToken}&topic=${topic}`,
        {
          method: "POST",
        }
      );
    }

    await setDoc(
      doc(db, "NOTIFICATIONS/GRANTED"),
      {
        list: arrayUnion(deviceToken),
      },
      { merge: true }
    );
  };

  const sendNotification = async (notif) => {
    const topics = [`${notif.bar}Admin`, "SuperAdmin"];
    const title = `[${notif.bar}] Vous avez une nouvelle réservation`;
    const body = `${notif.pseudo} a réserver une table de ${
      notif.peoples
    } personnes le ${formatDate(notif.dateAndHours)}`;

    // TODO: Optimize this to call only one time backend
    topics.forEach(async (topic) => {
      await fetch(
        `${process.env.VUE_APP_BACKEND}/notifications/send-notif?topic=${topic}&title=${title}&body=${body}`,
        {
          method: "POST",
        }
      );
    });
  };

  return {
    state,
    getGranted,
    addGranted,
    sendNotification,
  };
});
