import { defineStore } from "pinia";
import {
  getFirestore,
  setDoc,
  doc,
  getDocs,
  updateDoc,
  deleteField,
  query,
  collection,
  where
} from "firebase/firestore";
import { firebaseApp } from "../db/index";
import { reactive } from "vue";

export const useAdminStore = defineStore("admin", () => {
  const db = getFirestore(firebaseApp);

  const state = reactive({
    roles: {},
  });

  const addRole = (data) => {
    setDoc(
      doc(db, `USERS/${data.userUid}`),
      {
        role: {
          function: data.role,
          bar: data.role === "Admin" ? data.bar : "",
        },
      },
      { merge: true }
    );

    switch (data.role) {
      case "Admin":
        if (state.roles?.[data.role]?.users?.[data.bar]) state.roles[data.role].users[data.bar].push(data.email)
        else if (state.roles[data.role]?.users) state.roles[data.role].users = {...state.roles[data.role].users, ...{ [data.bar]: [data.email] }}
        else state.roles[data.role] = { users: { [data.bar]: [data.email] }}
        break;
      case "SuperAdmin":
        if (state.roles?.[data.role]?.users) state.roles[data.role].users.push(data.email);
        else state.roles[data.role] = { users: [data.email] }
        break;
    }

    setDoc(
      doc(db, `ROLES/${data.role}`),
      state.roles[data.role],
      { merge: true }
    );
  };

  const deleteRole = (data) => {
    updateDoc(doc(db, `USERS/${data.userUid}`), {
      role: deleteField(),
    });

    switch (data.role) {
      case "Admin":
        state.roles[data.role].users[data.bar] = state.roles[data.role].users[data.bar].filter((el) => el !== data.email);
        break;
      case "SuperAdmin":
        state.roles[data.role].users = state.roles[data.role].users.filter(
          (el) => el !== data.email
        );
        break;
    }

    updateDoc(doc(db, `ROLES/${data.role}`), {
      users: state.roles[data.role].users,
    });
  };

  const getRoles = async () => {
    const q = query(collection(db, "ROLES"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      state.roles = { ...state.roles, ...{ [doc.id]: doc.data() } };
    });
  };

  const getUserUid = async mail => {
    const q = query(collection(db, "USERS"), where("email", "==", mail));

    const querySnapshot = await getDocs(q);
    let userUid = null

    querySnapshot.forEach((doc) => userUid = doc?.id)
    return userUid
  }

  return {
    addRole,
    deleteRole,
    getRoles,
    getUserUid,
    state,
  };
});
