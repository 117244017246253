<template>
  <div class="footer-app">
    <div class="separator" />
    <all-sponsor />
    <div class="content">
      <div class="left">
        <div class="links">
          <router-link to="/mentions-legales" class="link">{{
            $t("footer.legal-notice")
          }}</router-link>
          <router-link to="/contact" class="link">{{
            $t("footer.contact")
          }}</router-link>
        </div>
        <div class="social-media">
          <a href="https://www.facebook.com/ReloadGamingBar" aria-label="Page facebook du reload gaming bar">
            <img alt="reload facebook" src="@/assets/facebook.svg" />
          </a>
          <a href="https://www.instagram.com/reloadgamingbar/" aria-label="Page instagram du reload gaming bar">
            <img alt="reload instagram" src="@/assets/instagram.svg" />
          </a>
          <a href="https://twitter.com/ReloadGamingBar" aria-label="Page twitter du reload gaming bar">
            <img alt="reload twitter" src="@/assets/twitter.svg" />
          </a>
        </div>
      </div>
      <div class="right">
        <img alt="Reload Gaming Bar Logo" src="@/assets/reload-logo.webp" class="logo" />
        <span class="reload-text">Reload Gaming Bar 2023 ©</span>
      </div>
    </div>
  </div>
</template>

<script>
import AllSponsor from "@/components/base/AllSponsor.vue";
export default {
  name: "FooterApp",
  components: { AllSponsor },
};
</script>

<style lang="scss" scoped>
.footer-app {
  .separator {
    height: 1rem;
    width: 100%;
    background-color: white;
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    background-color: #0e0e0e;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        .link {
          color: white;
          text-decoration: none;
        }
      }
      .social-media {
        display: flex;
        justify-content: space-between;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .logo {
        height: 10rem;
      }
      .reload-text {
        color: white;
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 760px) {
    .right {
      .logo {
        height: 8rem;
      }
      .reload-text {
        display: none;
      }
    }
  }
}
</style>
