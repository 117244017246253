import { defineStore } from "pinia";
import { reactive } from "vue";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider
} from "firebase/auth";
import { getFirestore, setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { firebaseApp } from "../db/index";
import { useBarsStore } from "./bars";
import { DateTime } from "luxon";

export const useAuthentificationStore = defineStore("authentification", () => {
  const auth = getAuth(firebaseApp);
  const db = getFirestore(firebaseApp);

  const barsStore = useBarsStore();

  const state = reactive({
    currentUser: null,
    userData: undefined,
  });

  async function registerUser(user) {
    const date = DateTime.now().ts.toString();
    await new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, user.email, user.password)
        .then(async userCredential => {
          isAuthenticated();
          await setDoc(doc(db, "USERS", userCredential.user.uid), {
            pseudo: user.pseudo,
            bar: user.bar,
            email: user.email,
            fidelityId: user.pseudo
              ?.slice(0, 2)
              .toUpperCase()
              .concat(date.substr(date.length - 4)),
          });
          resolve();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          reject(errorCode);
        });
    });
  }

  async function logUser(user) {
    await new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, user.email, user.password)
        .then((userCredential) => {          
          const userInfos = userCredential.user;
          state.currentUser = {
            email: userInfos.email,
            user: userInfos.uid,
          };
          resolve(userInfos);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          reject(errorCode);
        });
    });
  }

  async function resetPassword(email) {
    await new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          resolve("mail-send");
        })
        .catch((error) => {
          reject(error.code);
        });
    });
  }

  async function editMail(user) {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      user.password
    );
    await new Promise((resolve, reject) => {
      reauthenticateWithCredential(auth.currentUser, credential)
        .then(() => {
          updateEmail(auth.currentUser, user.mail)
            .then(async () => {
              await updateDoc(doc(db, `USERS/${auth.currentUser.uid}`), {
                email: user.mail
              });
              resolve("mail-edit")
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => reject(error.code));
    });
  }

  async function editPassword(user) {
    const credential = EmailAuthProvider.credential(auth.currentUser.email, user.oldPassword)
    await new Promise((resolve, reject) => {
      reauthenticateWithCredential(auth.currentUser, credential).then(() => {
        updatePassword(auth.currentUser, user.newPassword)
          .then(() => resolve('password-edit'))
          .catch(error => reject(error.code))
      }).catch(error => reject(error.code))
    })
  }

  function logout() {
    signOut(auth);
    state.currentUser = null;
  }

  const isAuthenticated = async () => {
    return await new Promise((resolve) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          state.currentUser = {
            email: user.email,
            user: user.uid,
          };
          const userData = await getDoc(doc(db, `USERS/${user.uid}`));
          state.userData = userData.data();
          barsStore.setDefaultBar(state.userData?.bar);
          resolve(user);
        } else resolve(undefined);
      });
    });
  };

  return {
    state,
    resetPassword,
    editMail,
    editPassword,
    isAuthenticated,
    registerUser,
    logUser,
    logout,
  };
});
