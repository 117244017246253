<template>
  <div :class="{ open: isMobile() }" class="navbar">
    <div v-if="isMobile()">
      <div class="content">
        <img
          v-if="isAdmin"
          @click="openedAdminMenu = !openedAdminMenu"
          src="@/assets/account.svg"
          alt="account"
        />
        <img src="@/assets/reload-logo.webp" alt="reload logo" class="logo" />
        <img
          @click="openedMenu = !openedMenu"
          src="@/assets/menu.svg"
          alt="menu"
        />
      </div>
      <div v-if="openedAdminMenu" class="opened-menu">
        <div class="main-elements">
          <div
            v-for="(el, i) in adminElements"
            :key="`${i}-el`"
            class="element"
          >
            <router-link :to="`${el.link}`" class="link">
              <span @click="openedAdminMenu = false">{{
                $t(`navbar.${el.name}`)
              }}</span>
            </router-link>
            <div class="separator" />
          </div>
        </div>
      </div>
      <div v-if="openedMenu" class="opened-menu">
        <div class="main-elements">
          <div v-for="(el, i) in elements" :key="`${i}-el`" class="element">
            <router-link :to="`${el}`" class="link">
              <span @click="openedMenu = false">{{ $t(`navbar.${el}`) }}</span>
            </router-link>
            <div class="separator" />
          </div>
        </div>
        <div class="auth-elements">
          <div
            v-for="(el, i) in accountOptions"
            :key="`${i}-account`"
            class="element"
          >
            <router-link :to="`${el.link}`" class="link">
              <span @click="openedMenu = false">{{
                $t(`navbar.${el.name}`)
              }}</span>
            </router-link>
            <div class="separator" />
          </div>
          <div v-if="isLogged" class="element">
            <span @click="logout">{{ $t("navbar.logout") }}</span>
            <div class="separator" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="content">
        <router-link to="/" class="link">
          <span class="element">{{ $t("navbar.home") }}</span>
        </router-link>
        <router-link to="/bar" class="link">
          <span class="element">{{ $t("navbar.bar") }}</span>
        </router-link>
        <img src="@/assets/reload-logo.webp" alt="reload logo" class="logo" />
        <router-link to="/franchise" class="link">
          <span class="element">{{ $t("navbar.franchise") }}</span>
        </router-link>
        <router-link to="/contact" class="link">
          <span class="element">{{ $t("navbar.contact") }}</span>
        </router-link>
        <div class="my-account">
          <div
            @click="showAccountMenu = !showAccountMenu"
            :class="{ open: showAccountMenu }"
            class="button"
          >
            <img src="@/assets/account.svg" alt="account" class="icon" />
            <span>{{ $t("navbar.my-account") }}</span>
            <img
              src="@/assets/triangle.svg"
              alt="triangle"
              :class="{ open: showAccountMenu }"
              class="icon triangle"
            />
          </div>
          <div v-if="showAccountMenu" class="opened-account">
            <router-link
              v-for="(option, i) in accountOptions"
              :key="`${i}-account`"
              :to="`/${option.link}`"
              class="link"
            >
              <span @click="showAccountMenu = false">{{
                $t(`navbar.${option.name}`)
              }}</span>
            </router-link>
            <span v-if="isLogged" @click="logout" class="link">{{
              $t("navbar.logout")
            }}</span>
          </div>
        </div>
      </div>
      <admin-navbar v-if="isAdmin" />
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils";
import { ref, computed } from "vue";
import { useAuthentificationStore } from "@/stores/authentification";
import AdminNavbar from "@/components/base/AdminNavbar.vue";
import router from "@/router";

export default {
  name: "NavbarApp",
  components: { AdminNavbar },
  setup() {
    const elements = ["home", "bar", "franchise", "contact"];
    const adminElements = [
      { name: "reservation", link: "reservation" },
      { name: "planning", link: "planning" },
      { name: "loyalty", link: "admin-fidelity" },
      { name: "franchise", link: "admin" },
    ];

    const openedMenu = ref(false);
    const openedAdminMenu = ref(false);
    const showAccountMenu = ref(false);

    const authentificationStore = useAuthentificationStore();

    const isLogged = computed(() =>
      authentificationStore.state?.currentUser ? true : false
    );

    const isAdmin = computed(() =>
      ["Admin", "SuperAdmin"].includes(
        authentificationStore.state.userData?.role?.function
      )
    );

    const accountOptions = computed(() => {
      if (isLogged.value)
        return [
          { name: "account", link: "compte" },
          { name: "parameters", link: "parametres" },
          { name: "loyalty", link: "fidelite" },
        ];
      else
        return [
          { name: "register", link: "inscription" },
          { name: "login", link: "connexion" },
        ];
    });

    const logout = () => {
      authentificationStore.logout();
      showAccountMenu.value = false;
      openedMenu.value = false;
      router.push("/");
    };

    return {
      elements,
      adminElements,
      openedMenu,
      openedAdminMenu,
      isMobile,
      accountOptions,
      showAccountMenu,
      isAdmin,
      isLogged,
      logout,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  z-index: 10001;
  width: 100%;
  .content {
    position: relative;
    height: 2rem;
    background-color: #0e0e0e;
    color: white;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    border-bottom: 3px solid #ffffff;
    border-radius: 0px 0px 15px 15px;
    span {
      font-family: "Inter";
      font-size: 1.2rem;
      text-transform: uppercase;
      align-self: center;
    }
    .my-account {
      position: absolute;
      right: 1rem;
      .button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        border-radius: 15px;
        border: 1px solid white;
        padding: 0.5rem;
        .icon {
          height: 1.5rem;
          &.triangle {
            filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(151deg)
              brightness(110%) contrast(106%);
            height: 0.7rem;
            rotate: 180deg;
            &.open {
              rotate: 0deg;
            }
          }
        }
        &.open {
          border-radius: 15px 15px 0 0;
        }
      }
      .opened-account {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;
        background-color: black;
        align-items: flex-end;
        border-radius: 0 0 15px 15px;
        border: 1px solid white;
        margin-top: -1px;
        .link {
          margin: 1rem;
          display: flex;
          align-self: flex-end;
          cursor: pointer;
        }
      }
    }
    .logo {
      height: 7rem;
      bottom: -0.5rem;
    }
  }
  .link {
    color: white;
    text-decoration: none;
  }
  @media screen and (max-width: 760px) {
    .content {
      justify-content: space-between;
      z-index: 999;
    }
    .opened-menu {
      position: fixed;
      top: 0;
      bottom: 0;
      min-height: 100vh;
      width: 100vw;
      background-color: #1c1b1b;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .main-elements {
        margin-top: 5rem;
      }
      .main-elements,
      .auth-elements {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;
        gap: 2rem;
        .element {
          font-family: "Inter";
          font-size: 1.5rem;
          font-weight: 300;
          text-transform: uppercase;
          text-align: right;
          span {
            padding-right: 2rem;
          }
          .separator {
            width: 100%;
            height: 2px;
            background-color: white;
          }
        }
      }
    }
  }
}
</style>
