<template>
  <div class="app">
    <span v-if="loading">loading...</span>
    <template v-else>
      <navbar-app v-if="!['/connexion', '/inscription'].includes($route.path)" />
      <router-view></router-view>
      <footer-app v-if="!['/connexion', '/inscription'].includes($route.path)" />
    </template>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useAuthentificationStore } from "@/stores/authentification";
import { useAdminStore } from "@/stores/admin";
import { useBarsStore } from "@/stores/bars";
import { useNotificationsStore } from './stores/notifications';
import NavbarApp from '@/components/base/NavbarApp.vue';
import FooterApp from '@/components/base/FooterApp.vue';
import { onMounted, ref } from 'vue';
import { getMessaging, getToken } from "firebase/messaging";

export default {
  name: "App",
  components: {
    NavbarApp,
    FooterApp
  },
  setup() {
    const loading = ref(false)

    const authentificationStore = useAuthentificationStore();
    const adminStore = useAdminStore();
    const barsStore = useBarsStore();
    const notificationsStore = useNotificationsStore()
    const route = useRoute()

    const messaging = getMessaging();

    onMounted(async () => {
      loading.value = true
      await authentificationStore.isAuthenticated()
      barsStore.getBars()

      if (authentificationStore.state?.currentUser) adminStore.getRoles()

      getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_MESSAGING_KEY }).then(async(currentToken) => {
          if (currentToken) await notificationsStore.addGranted(currentToken)
          else requestPermission()
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      loading.value = false
    })

    const requestPermission = () => Notification.requestPermission()

    return {
      route,
      loading
    }
  },
};
</script>

<style>
#app {
  font-family: "Inter";
  text-align: center;
  background-image: url("@/assets/background.webp");
  background-size: cover;
}
html,
body {
  margin: 0px !important;
  padding: 0px !important;
}
.app {
  display: flex;
  flex-direction: column;
}
</style>
