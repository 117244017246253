import { defineStore } from "pinia";
import {
  getFirestore,
  setDoc,
  doc,
  query,
  getDocs,
  deleteDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { reactive } from "vue";
import { DateTime } from "luxon";

export const useBarsStore = defineStore("bars", () => {
  const db = getFirestore();
  const storage = getStorage();

  const state = reactive({
    barsList: [],
    barInfo: {},
    defaultBar: undefined,
  });

  const setDefaultBar = (bar) => (state.defaultBar = bar);

  const addBar = (data) => {
    setDoc(doc(db, `BARS/${data.city}`), data, { merge: true });
    state.barsList.push(data);
  };

  const addPlanning = (planning) => {
    const date = DateTime.now().setLocale("fr");
    const fileName =
      planning.month === "currentPlanning"
        ? date.toFormat("MMMMyyyy")
        : date.plus({ month: 1 }).toFormat("MMMMyyyy");
    const pictureRef = ref(storage, `PLANNING/${planning.bar}/${fileName}`);

    return new Promise((resolve) => {
      uploadBytes(pictureRef, planning.picture).then((res) => {
        resolve(res);
      });
    });
  };

  const deletePlanning = (planning) => {
    const date = DateTime.now().setLocale("fr");
    const fileName =
      planning.picture === "current"
        ? date.toFormat("MMMMyyyy")
        : date.plus({ month: 1 }).toFormat("MMMMyyyy");

    state.barInfo[`${planning.picture}Planning`] = ""
    const imageRef = ref(storage, `PLANNING/${planning.bar}/${fileName}`);
    deleteObject(imageRef);
  };

  const getBars = async () => {
    const q = query(collection(db, "BARS"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      state.barsList.push(doc.data());
    });
  };

  const getBarInfo = (bar) => {
    onSnapshot(doc(db, `BARS/${bar}`), async (doc) => {
      const infos = { ...doc.data() };
      const date = DateTime.now().setLocale("fr");
      const planningTypes = [
        { name: "current", month: date.toFormat("MMMMyyyy") },
        { name: "next", month: date.plus({ month: 1 }).toFormat("MMMMyyyy") },
      ];
      const promises = planningTypes.map(async (type) => {
        const pictureRef = ref(storage, `PLANNING/${bar}/${type.month}`);

        await getDownloadURL(pictureRef)
          .then(
            (url) =>
              (infos[`${type.name}Planning`] = {
                name: `${type.name}Planning`,
                url,
              })
          )
          .catch((error) => {
            console.log("error", error);
          });
      });
      await Promise.all(promises);
      state.barInfo = infos;
    });
  };

  const deleteBar = async (bar) => {
    state.barsList = state.barsList.filter((el) => bar.city !== el.city);
    await deleteDoc(doc(db, `BARS/${bar.city}`));
  };

  const updateKaraoke = async data => {
    await setDoc(doc(db, `BARS/${data.bar}`), { hasKaraoke: data.hasKaraoke }, { merge: true });
  }

  const updateMaxReservations = async data => {
    await setDoc(doc(db, `BARS/${data.bar}`), { maxReservations: data.maxReservations }, { merge: true });
  }

  return {
    addBar,
    state,
    getBars,
    getBarInfo,
    deleteBar,
    deletePlanning,
    addPlanning,
    setDefaultBar,
    updateKaraoke,
    updateMaxReservations
  };
});
