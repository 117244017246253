<template>
  <div class="all-sponsor">
    <a v-for="(el, i) in sponsors" :key="i" target="_blank" :href="el?.url" :aria-label="`Liens vers ${el.sponsor}`" class="sponsor">
      <img :src="require(`@/assets/${el.sponsor}.svg`)" :alt="el.sponsor" />
    </a>
  </div>
</template>

<script>
export default {
  name: "AllSponsor",
  setup() {
    const sponsors = [
      { sponsor: "dayluge", url: "https://www.facebook.com/blackwhitetoile" },
      { sponsor: "qrserv", url: "https://www.qr-serv.fr" },
      { sponsor: "mlgroupe", url: "https://www.reloadgamingbar.com" },
      { sponsor: "monster", url: "https://www.monsterenergy.com/" },
      { sponsor: "ldlc", url: "https://www.ldlc.com/" },
    ];

    return { sponsors };
  },
};
</script>

<style lang="scss" scoped>
.all-sponsor {
  background-color: #2e2e2e;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 3rem;
  align-items: center;

  @media screen and (max-width: 760px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
    .sponsor:last-child:nth-child(3n - 1) {
      display: inline-grid;
      grid-column-end: 3;
      grid-column-start: 1;
    }
  }
}
</style>
