import { createWebHistory, createRouter } from "vue-router";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, getDoc, doc } from 'firebase/firestore'

initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
});

const auth = getAuth();
const db = getFirestore()

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "HomePage",
    component: () => import("@/views/HomePage.vue"),
  },
  {
    path: "/franchise",
    name: "Franchise",
    component: () => import("@/views/FranchisePage.vue"),
  },
  {
    path: "/bar",
    name: "Bar",
    component: () => import("@/views/BarPage.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/ContactPage.vue"),
  },
  {
    path: "/inscription",
    name: "Inscription",
    component: () => import("@/views/ConnexionPage.vue"),
  },
  {
    path: "/connexion",
    name: "Connexion",
    component: () => import("@/views/ConnexionPage.vue"),
  },
  {
    path: "/compte",
    name: "Compte",
    component: () => import("@/views/AccountPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/fidelite",
    name: "Fidelite",
    component: () => import("@/views/FidelityPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/planning",
    name: "Planning",
    component: () => import("@/views/PlanningPage.vue"),
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/AdminPage.vue"),
    meta: { requiresAdmin: true },
  },
  {
    path: "/reservation",
    name: "Reservation",
    component: () => import("@/views/ReservationPage.vue"),
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin-fidelity",
    name: "AdminFidelity",
    component: () => import("@/views/AdminFidelityPage.vue"),
    meta: { requiresAdmin: true },
  },
  {
    path: "/parametres",
    name: "Paramètres",
    component: () => import("@/views/ParamsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/mentions-legales",
    name: "Mentions Légales",
    component: () => import("@/views/LegalNoticePage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

const isAuthenticated = () => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) resolve(user);
      else resolve(undefined);
    });
  });
};

const isAdmin = () => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, async (user) => {
      if (!auth?.currentUser) {
        return resolve(undefined)
      }
      const querySnapshot = await getDoc(doc(db, 'USERS', auth?.currentUser?.uid))
      if (!!querySnapshot?.data()?.role) {
        resolve(user)
      }
      else resolve(undefined);
    });
  });
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin)

  if (requiresAuth && !(await isAuthenticated())) {
    next("/home");
  }
  if (requiresAdmin && !(await isAdmin())) {
    next("/home")
  } else {
    next();
  }
});

export default router;
